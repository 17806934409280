import getGoogleIDs, {debugGoEvent} from './getGoogleIDs.js';
import {getS7Value} from './getSValues';

const TRACKING_CLASS = 'is-tracking';

const SEM_USER_COOKIE_NAME = 'PPCUser';
const SEM_USER_COOKIE_VALUE = '1';
const SEM_USER_QUERY_NAME = 'cfsource';
const SEM_USER_QUERY_VALUE = 'sem';
const MT_USER_QUERY_NAME = 'customnative6';
const MT_USER_S7_COOKIE_NAME = 'MTUSER_S7';
const SWIMLANE_COOKIE_NAME = 'cf_trk_swimlane';
const NUMBER_OF_SWIMLANES = 4;
const SWIMLANE_EXPIRATION_DAYS = 30;

const isTracking = document.querySelector('body').classList.contains(TRACKING_CLASS);
const urlBase = window.site_settings.admin_ajax;

export function getS17Value()
{
    return document.body.querySelector('#customfield1')?.value || '';
}

/**
 * Determine whether the current user is an SEM user or not.
 * @returns {boolean} - if the user is an SEM user or not
 */
export function isSemUser()
{
    return (getCookie(SEM_USER_COOKIE_NAME) === SEM_USER_COOKIE_VALUE) ||
        (getQSValue(SEM_USER_QUERY_NAME) === SEM_USER_QUERY_VALUE);
}

/**
 * Determine whether the current user is a Media Transformation user or not.
 * @returns {boolean} - if the user is a MT user or not
 */
export function isMTUser()
{
    let $queryParamValue = getQSValue(MT_USER_QUERY_NAME);
    let $cookieValue = getCookie(MT_USER_S7_COOKIE_NAME);

    if (!isCrawler() && ($queryParamValue || $cookieValue)
    ) {
        // Update cookie if query param is set
        if ($queryParamValue && $queryParamValue !== $cookieValue) {
            setCookie(MT_USER_S7_COOKIE_NAME, $queryParamValue);
        }

        return true;
    } else {
        return false;
    }
}

/**
 * Fetch the swimlane cookie and return the swimlane id as an integer.
 * @returns {null|number} - the swimlane id, or null if there is no cookie, or the cookie is set to an invalid value
 */
export function getSwimlane()
{
    const swimlaneCookie = getCookie(SWIMLANE_COOKIE_NAME);
    if (swimlaneCookie.length > 0) {
        const swimlane = parseInt(swimlaneCookie, 10);
        if (!isNaN(swimlane) && swimlane > 0 && swimlane <= NUMBER_OF_SWIMLANES) {
            return swimlane;
        } else {
            return null;
        }
    }

    return null;
}

/**
 * Set the swimlane for the current user.
 * If the user already has a swimlane, this function will do nothing.
 * So it is safe to call in any circumstance.
 */
export function assignSwimlaneToUser()
{
    if (getSwimlane() === null) {
        // assign a new swimlane!
        const swimlane = 1 + Math.floor(Math.random() * NUMBER_OF_SWIMLANES);

        // swimlane lasts 24 hours
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (SWIMLANE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000));

        console.log('swimlane');

        document.cookie = `${SWIMLANE_COOKIE_NAME}=${swimlane};path=/;expires=${expiration.toUTCString()}`;
    }
}

export function setCookie(cname, cvalue, cdays = 14)
{
    const d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000 * cdays)); // 14 days
    let expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Read a cookie
 * @param {string} cname - cookie name
 * @returns {string|""} - cookie value, or empty string if cookie is not set
 */
export function getCookie(cname)
{
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

/**
 * Read query parameter from current URL
 * @param key - which parameter to read
 * @returns {string} - parameter value
 */
export function getQSValue(key)
{
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                '^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
                'i'
            ),
            '$1'
        )
    );
}

/**
 * Determine whether the current user agent is a crawler.
 * @returns {boolean} - true the user agent is a crawler, false if not
 */
function isCrawler()
{
    const userAgent = navigator.userAgent? navigator.userAgent.toLocaleLowerCase() : '';
    const patternt = '/Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver/'.toLocaleLowerCase();
    const regex = new RegExp(patternt, 'i');

    if (userAgent !== '') {
        if (regex.test(userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}

/**
 * Fetch s1 value from admin-ajax
 * @param {function} successCallback - function to call if request was successful
 * @param {function} failureCallback - function to call if request was unsuccessful
 */
function loadTrackingValuesFromServer(successCallback, failureCallback)
{
    const s17 = getS17Value();
    let url = `${urlBase}?action=get_tracking&referrer=${encodeURIComponent(document.referrer)}`;

    if (s17) {
        url = `${url}&s17=${s17}`
    } else {
        url = `${url}&s17=SO_s17_not_set`
    }

    if (isSemUser()) {
        url = `${url}&cfsource=sem&s1=${getQSValue('s1')}&cpid=${getQSValue('cpid')}`;
    }

    const ajax = new XMLHttpRequest();
    ajax.open('GET', url);
    ajax.send();
    ajax.onreadystatechange = function () {
        if (ajax.readyState === XMLHttpRequest.DONE) {
            if (ajax.status === 200) {
                let s1Data = JSON.parse(ajax.responseText);
                window.cf_dg_clk_id = s1Data.cf_dg_clk_id;
                window.ckm_request_id = s1Data.ckm_request_id;
                if (typeof (successCallback) === 'function') {
                    successCallback();
                }
            } else {
                if (typeof (failureCallback) === 'function') {
                    failureCallback();
                }
            }
        }
    }
}

// send GTM event with s1 value
// this allows us to tie GTM/GA events to a particular CF session
function sendCFEvent(cf, ckm)
{
    const s1 = cf + '.' + ckm,
        subid = document.querySelector('#subId');

    window.dispatchEvent(new CustomEvent('click_pixel_fired', {
        'detail': {
            's1' : s1,
        },
    }));

    window.dataLayer.push({
        'event': 'CF_TRACKING',
        'eventCategory': 'CF_TRACKING',
        'eventAction': 'CLICK_S1',
        'eventLabel': s1,
        's1-value': s1,
    });

    if (typeof subid !== 'undefined' && subid !== null) {
        console.log('subid', subid, s1);
        subid.value = s1;
    }
}

// call admin-ajax gago function with analytics/optimize data
function gago()
{
    getGoogleIDs('gago')
        .then((googleIds) => {
            let clientId = null;
            let variationId = null;
            let experimentId = null;

            if (googleIds.optimizeData !== null) {
                experimentId = googleIds.optimizeData.experimentId;
                variationId = googleIds.optimizeData.variationId;
            } else {
                experimentId = window.site_settings.experiment.id;
                variationId = window.site_settings.experiment.variant;
                debugGoEvent({
                    'event': 'gagoOptimizeTrackingValuesAreNull',
                });
            }

            if (googleIds.analyticsData !== null) {
                clientId = googleIds.analyticsData.clientId;
            } else {
                clientId = document.cookie.match(/_ga=(.+?);/)[1]?.split('.')?.slice(-2)?.join('.');
                if (!clientId) {
                    clientId = null;
                }
                debugGoEvent({
                    'event': 'gagoAnalyticsClientIdLoadedFromCookies',
                });
            }
            let s7Value = getS7Value() || null;
            let gagoData = {
                action: 'gago',
                sub_id: window.cf_dg_clk_id + '.' + window.ckm_request_id,
                google_analytics_client_id: clientId,
                google_optimize_experiment_id: experimentId,
                google_optimize_experiment_variation_id: variationId,
                referrer: document.referrer,
                page_type: getS17Value(),
                page_url: document.location.href,
                s7: s7Value,
            };

            try {
                const fetchUrl = `${urlBase}?${(new URLSearchParams(gagoData)).toString()}`;
                fetch(fetchUrl).then((response) => console.debug(response));
            } catch (error) {
                console.error(error);
            }

            debugGoEvent({
                'event': 'gagoTrackingCompleted', googleIds,
            });
        });
}

function swapRP()
{
    if (getCookie('PPCUser') === '1' || getQSValue('cfsource') === 'sem') {
        //SEM User
        //console.log("SEM User");
        replaceRP(window.rp_sem, window.rp_seo);
    } else {
        //SEO User
        //console.log("SEO User");
        replaceRP(window.rp_seo, window.rp_sem);
    }
}

function replaceRP(arr, rep)
{
    for (var i = 0; i < rep.length; i++) {
        if (arr[i].dnis !== '') {
            var matches = document.querySelectorAll('span[ringpoolid="' + rep[i].rpid + '"]', 'a[ringpoolid="' + rep[i].rpid + '"]');
            matches.forEach(function (item) {
                item.setAttribute('ringpoolid', arr[i].rpid);
                item.setAttribute('placeholder', arr[i].dnis);
                window.atags = item.getElementsByTagName('a');
                for (var j = 0; j < window.atags.length; j++) {
                    window.atags[j].setAttribute('href', 'tel:' + arr[i].dnis);
                    window.rptext = window.atags[j].innerHTML.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/, arr[i].dnis);
                    window.atags[j].innerHTML = window.rptext;
                }
            });
        }
    }
    reloadRP();
}

function reloadRP()
{
    if (typeof window.cl_object.runner === 'function') {
        window.cl_object.runner();
        console.log('Rerun Ringpool');
    } else {
        setTimeout(reloadRP, 500);
    }
}

function startTracking()
{
    // required for child pixel
    window.getCookie = getCookie;

    window.dataLayer = window.dataLayer || [];

    window.cl_object = {
        runner: null,
    };

    // register callback for autoringpool script, so we can manually re-run the ringpool ourselves if needed
    window.cl_on_complete = function (cl_runner) {
        window.cl_object.runner = function () {
            cl_runner();
        }
        cl_runner();
    };

    // we have a cookied user! no network request required
    if (getCookie('cf_dg_clk_id') !== '' && getQSValue('cfsource') !== 'sem') {
        window.cf_dg_clk_id = getCookie('cf_dg_clk_id');
        window.ckm_request_id = getCookie('ckm_request_id');
        sendCFEvent(window.cf_dg_clk_id, window.ckm_request_id);
        swapRP();
        gago();

        window.dispatchEvent(new CustomEvent('tracking_request_complete'));
    } else {
        // this is a new user, we have to request the tracking values from the admin-ajax endpoint
        loadTrackingValuesFromServer(
            function () {
                sendCFEvent(window.cf_dg_clk_id, window.ckm_request_id);
                swapRP();
                gago();
                window.dispatchEvent(new CustomEvent('tracking_request_complete'));
            },
            function () {
                console.log('Error getting S1 Values');
            }
        );
    }
}
export default function init()
{
    if (!isTracking) {
        console.log('no tracking');
        startTracking();
    } else {
        console.log('tracking');
    }
}
