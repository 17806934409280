import {getCookie} from './index';

export const getS1Value = () => {
    let s1Value = null;
    const cf_dg_clk_id = getCookie('cf_dg_clk_id') || null;
    const ckm_request_id = getCookie('ckm_request_id') || null;

    if (cf_dg_clk_id && ckm_request_id) {
        s1Value = cf_dg_clk_id + '.' + ckm_request_id;
    } else if ((typeof window.cf_dg_clk_id !== 'undefined' && typeof window.ckm_request_id !== 'undefined')
        && (window.cf_dg_clk_id !== null && window.ckm_request_id !== null)) {
        s1Value = `${window.cf_dg_clk_id}.${window.ckm_request_id}`
    }

    return s1Value.length > 5 ? s1Value : null;
}


export const getS7Value = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const customnative6Value = urlParams.get('customnative6');

    let s7Value = '';
    if (customnative6Value) {
        s7Value = customnative6Value;
    } else {
        let s7CookieValue = getCookie('MTUSER_S7');
        if (s7CookieValue) {
            s7Value = s7CookieValue;
        }
    }

    return s7Value;
}
